import { useState } from 'react';
import { Box, Tooltip } from '@mui/material';

const ColorChip = ({ colorName, bgColor, setActiveColor, activeColor }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(false);
    // setIsActive(!isActive);
    // setActiveColor(colorName);
  };

  if (!bgColor) {
    return null;
  }

  return (
    <Tooltip title={colorName}>
      <Box
        component="div"
        onClick={handleClick}
        sx={{
          bgcolor: bgColor,
          width: '16px',
          height: '16px',
          mr: 1,
          mb: 1,
          borderRadius: '50%',
          border: isActive ? '1px solid #fff' : '1px solid #d2d2d2',
          outline: isActive && '1px solid #818181',
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        &nbsp;
      </Box>
    </Tooltip>
  );
};

const AvailableColors = ({ colors }) => {
  const colorMapping = {
    verde: '#018D48',
    pistacho: '#009801',
    negro: '#262626',
    morado: '#6A225E',
    azul: '#1B2767',
    azulino: '#0435B2',
    celeste: '#009ECF',
    rojo: '#DD3236',
    naranjo: '#CB541A',
    amarillo: '#EDE128',
    blanco: '#ffffff',
    crudo: '#E0DACB',
    crea: '#F4F0EA',
    lona: '#D7D0CD'
  };
  const [activeColor, setActiveColor] = useState(colors[0]);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {colors.map((colorName, index) => (
        <ColorChip
          key={index}
          colorName={colorName}
          bgColor={colorMapping[colorName]}
          setActiveColor={setActiveColor}
          activeColor={activeColor}
        />
      ))}
    </div>
  );
};

export default AvailableColors;
