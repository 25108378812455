import car1 from '../assets/images/car1.png';
import car2 from '../assets/images/car2.png';
import car3 from '../assets/images/car3.png';

import car1Webp from '../assets/images/car1.webp';
import car2Webp from '../assets/images/car2.webp';
import car3Webp from '../assets/images/car3.webp';

import { Helmet } from 'react-helmet';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import styled from '@emotion/styled';

const CustomCarousel = () => {


  const CarouselImage = styled('img')`
    object-fit: cover;
    width: 100%;
    height: 450px;

    @media (max-width: 768px) {
      height: 130px;
    }
  `;


  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href={car1Webp} type="image/webp" />
      </Helmet>

      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={3000}
        showThumbs={false}
        showStatus={false}
        showIndicators={true}
        showArrows={true}
      >
        <div>
          <picture>
            <source srcSet={car1Webp} type="image/webp" />
            <CarouselImage
              src={car1}
              alt="Descubre nuestros bolsos estampados"
            />
          </picture>
        </div>
        <div>
          <picture>
            <source srcSet={car2Webp} type="image/webp" />
            <CarouselImage
              src={car2}
              alt="Bolsas ecológicas estampadas"
              loading="lazy"
            />
          </picture>
        </div>
        <div>
          <picture>
            <source srcSet={car3Webp} type="image/webp" />
            <CarouselImage src={car3} alt="Ropa de trabajo" loading="lazy" />
          </picture>
        </div>
      </Carousel>
      <style jsx>{`
      @media (min-width: 769px) {
        .carousel-root {
          height: 450px;
        }
      }
        @media (max-width: 768px) {
          .carousel-root {
            height: 130px;
          }
        }
    `}</style>

    </>
  );
};

export default CustomCarousel;
