import { Container, Grid, Typography, Link, Box } from '@mui/material';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

import { styled } from '@mui/system';

const StyledFooter = styled('footer')({
  backgroundColor: '#333232',
  color: '#fff',
  padding: '2rem 0',
});

const SocialIcons = styled('div')({
  display: 'flex',
  justifyContent: 'left',
  gap: '1rem',
  marginTop: '1rem',
});

const FooterLinks = styled('ul')({
  listStyle: 'none',
  paddingLeft: 0,
  lineHeight: 1.6,
});

const Footer = () => {
  return (
    <StyledFooter sx={{marginTop: '3rem'}}>
      <Container>
        <Grid container spacing={3}>
          <Grid item md={4}>
            <Typography variant="h6">Contacto</Typography>
            <Typography>Av. Lauquen 609, Los Ángeles, Chile</Typography>
            <Typography>+56 9 85206460</Typography>
            <Typography>biobolsasany@gmail.com</Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="h6">Redes sociales</Typography>
            <SocialIcons>
              <Link href="https://www.facebook.com/Biobolsas" aria-label="Síguenos en Facebook" target="_blank" rel="noopener noreferrer" color="inherit">
                <FacebookIcon />
              </Link>
              <Link href="https://www.instagram.com/biobolsascl/" aria-label="Síguenos en Instagram" target="_blank" rel="noopener noreferrer" color="inherit">
                <InstagramIcon />
              </Link>
              <Link href="https://twitter.com/biobolsas_CL" aria-label="Síguenos en Twitter" target="_blank" rel="noopener noreferrer" color="inherit">
                <TwitterIcon />
              </Link>
            </SocialIcons>
          </Grid>
          <Grid item md={4}>
            <Typography variant="h6">Enlaces importantes</Typography>
            <FooterLinks>
              <li>
                <Link href="/acerca-de" color="inherit">Acerca de nosotros</Link>
              </li>
              <li>
                <Link href="/productos" color="inherit">Productos</Link>
              </li>
              <li>
                <Link href="/contacto" color="inherit">Contáctanos</Link>
              </li>
            </FooterLinks>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Grid container>
            <Grid item md={12}>
              <Typography align="center" variant="body2">&copy; {new Date().getFullYear()} Biobolsas. Todos los derechos reservados.</Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
