import { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import ProductCard from './ProductCard';

const ProductList = ({ products = [], showAll = false }) => {
    const [visibleProducts, setVisibleProducts] = useState(showAll ? products.length : 6);

    useEffect(() => {
        setVisibleProducts(showAll ? products.length : 6);
    }, [products.length, showAll]);

    return (
        <section id="products" className="mt-4">
            <Grid container spacing={4}>
                {!products || products.length === 0 ?

                    <Typography variant="h5" align="center" color="textSecondary" m={5}>
                        No hay bolsas disponibles, intenta más tarde.
                    </Typography>

                    :
                    products.slice(0, visibleProducts).map((product) => (
                        <Grid key={product.id} item xs={12} md={6} lg={4}>
                            <ProductCard product={product} />
                        </Grid>
                    ))
                }
            </Grid>
        </section>
    );
};

export default ProductList;
