import { useState, useEffect } from 'react';

function ProductImage(props) {
    const [imageUrl, setImageUrl] = useState('');
    const [pngImageUrl, setPngImageUrl] = useState('');
    const size = props.size || 'sm';

    useEffect(() => {
        import(`../assets/images/products/${size}/${props.imageFileName}.webp`)
            .then((module) => {
                setImageUrl(module.default);
            })
            .catch((error) => {
                console.log(`Error loading image: ${error}`);
            });

        import(`../assets/images/products/${size}/${props.imageFileName}.png`)
            .then((module) => {
                setPngImageUrl(module.default);
            })
            .catch((error) => {
                console.log(`Error loading image: ${error}`);
            });
    }, [props.imageFileName, size]);

    return (
        <picture>
            <source srcSet={`${imageUrl}`} type="image/webp" />
            <img
                src={`${pngImageUrl}`}
                width="400"
                height="400"
                alt={props.altText}
                className="product-image"
                loading="lazy"
            />
        </picture>
    );
}

export default ProductImage;
