import { useState } from 'react';
import { Box, Typography, Tabs, Tab, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';

const ProductInfo = ({ product }) => {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const styles = {
        container: {
            width: '100%',
            mt: 5,
            px: { xs: 2, sm: 0 }, // adds padding in the x-axis for mobile version
            display: 'flex',
            flexDirection: 'column', // sets the flex direction based on the device
            // alignItems: { sm: 'center' }, // aligns items to the center for desktop version
        },
        tabContainer: {
            borderBottom: 1,
            borderColor: 'divider'
        },
        tab: {
            textTransform: 'capitalize'
        },
        table: {
            minWidth: 350
        },
        tableCell: {
            fontWeight: 'bold'
        }
    }

    return (
        <Box sx={styles.container}>
            <Box sx={styles.tabContainer}>
                <Tabs value={value} onChange={handleChange} variant="scrollable">
                    <Tab label="Descripción" sx={styles.tab} />
                    <Tab label="Especificaciones técnicas" sx={styles.tab} />
                    <Tab label="Condiciones de entrega" sx={styles.tab} />
                </Tabs>
            </Box>
            {value === 0 && (
                <Box sx={{ p: 3 }}>
                    {product.description.split('\n').map((desc, index) => (
                        <Typography key={index} variant="body1" align='justify' mt={1}>
                            {desc}
                        </Typography>
                    ))}

                </Box>
            )}
            {value === 1 && (
                <Box sx={{ p: 3 }}>
                    <TableContainer component={Paper}>
                        <Table sx={styles.table}>
                            <TableHead>
                            </TableHead>
                            <TableBody>
                                {product.height &&
                                    <TableRow>
                                        <TableCell sx={styles.tableCell}>Alto</TableCell>
                                        <TableCell>{product.height}</TableCell>
                                    </TableRow>
                                }
                                {product.width &&
                                    <TableRow>
                                        <TableCell sx={styles.tableCell}>Ancho</TableCell>
                                        <TableCell>{product.width}cm</TableCell>
                                    </TableRow>
                                }
                                {product.material &&
                                    <TableRow>
                                        <TableCell sx={styles.tableCell}>Material</TableCell>
                                        <TableCell>{product.material}</TableCell>
                                    </TableRow>
                                }
                                {Array.isArray(product.colors) && product.colors.length > 0 &&
                                    <TableRow>
                                        <TableCell sx={styles.tableCell}>Colores</TableCell>
                                        <TableCell>{product.colors.join(', ').toLowerCase()}</TableCell>
                                    </TableRow>
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
            {value === 2 && (
                <Box sx={{ p: 3 }}>
                    <Typography variant="body1">
                        ¡Enviamos a todo Chile al comprar {product.title}! Nuestros productos están disponibles para su envío a través de Transportes Chevalier, Starken, Chilexpress y Pullman Cargo u otro medio a convenir. También ofrecemos la opción de retirar en nuestra oficina. ¡No esperes más para obtener tus productos de alta calidad a un precio competitivo!
                    </Typography>
                </Box>
            )}
        </Box>
    )
};
export default ProductInfo;
