import { useEffect } from 'react';
import ProductList from '../components/ProductList';
import { products_tnt } from '../components/ProductsData';
import { Helmet } from 'react-helmet';
import { Container, Typography, Box, Grid } from '@mui/material';

import ContentSection from '../components/ContentSection';
import FAQSection from '../components/FaqSection';
import CardsSection from '../components/CardsSection';


const pageTitle = "Bolsas Reutilizables de TNT | Biobolsas";
const pageDescription = "Descubre nuestro amplio catálogo de bolsas reutilizables de tela, TNT, algodón y yute. Personaliza tus bolsas con logotipos y diseños únicos. ¡Di chao al plástico con Biobolsas!";

const ProductsPage = () => {

  useEffect(() => {
    document.title = pageTitle;
  }, []);

  return (
    <Container>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content="bolsas de tnt, bolsas reutilizables, bolsas de tela, bolsas de algodón, bolsas de yute, bolsas personalizadas, bolsas con logo, catálogo de productos" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Box mt={4}>
        <Grid container justifyContent="center" px={5}>
          <Grid item textAlign={'center'}>
            <Typography variant="overline" component="small">Descubre Nuestras</Typography>
            <Typography variant="h2">Bolsas de TNT</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="body1" align="justify" sx={{ padding: '0 3rem', display: { xs: 'none', md: 'block' } }}>
              Aquí encontrarás una amplia variedad de bolsas ecológicas de TNT con estampado personalizado en serigrafía. Estas bolsas están diseñadas para ofrecer una alternativa sostenible y resistente a las bolsas plásticas de un solo uso. Además, son personalizables con el logo o diseño que desees. Además encontrarás una gran variedad de tamaños y colores de bolsas de TNT personalizables, lo que te permite elegir la opción que mejor se adapte a las necesidades de tu empresa. Además, nuestras bolsas son perfectas para eventos corporativos, regalos de empresa y para la promoción de marcas y productos.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <ProductList products={products_tnt} showAll={true} />

      <ContentSection />
      <FAQSection mt={4} sx={{ marginTop: '2rem' }} />
      <CardsSection mt={3} />
    </Container>
  );
};

export default ProductsPage;
