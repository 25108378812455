import productsData from '../data/products.json';


class Product {
  constructor(productData) {

    this.id = productData.id || null;
    this.imageKey = productData.imageKey || null;
    this.imageFileName = productData.imageFileName || null;
    this.altText = productData.altText || null;
    this.title = productData.title || null;
    this.category = productData.category || null;
    this.material = productData.material || null;
    this.height = productData.height || null;
    this.width = productData.width || null;
    this.depth = productData.depth || null;
    this.price = parseInt(productData.price) || null;
    this.tags = productData.tags || [];
    this.colors = productData.colors || [];
    this.shortDescription = productData.description || null;

    if (!this.title || !this.material) {
      console.log(productData);
      throw new Error('Required properties (title, material) missing');
    }
    
    this.slug = `${this.title.toLowerCase().replace(/\s+/g, '-')}`
    this.url = `/productos/detalle/${this.slug}`;

    this.description = `Este producto es perfecto para aquellos que buscan ${productData.title ? `un ${productData.title.toLowerCase()}` : 'un artículo'} ${productData.material ? `hecho de ${productData.material.toLowerCase()}` : 'de alta calidad'}.

    ${productData.height || productData.width || productData.depth ? `Con una altura de ${productData.height || 'desconocida'}, un ancho de ${productData.width || 'desconocido'}${productData.depth ? `, y una profundidad de ${productData.depth}` : ''}` : ''}
    
    ${productData.colors && productData.colors.length ? `Este producto está disponible en los colores ${productData.colors.join(', ').toLowerCase()}.` : ''}
    
    ${productData.imageFileName ? '\nNo olvide revisar la imagen del producto en la que se puede apreciar la textura y más detalles.' : ''}`;

    this.description += `\nDescubre nuestra increíble selección de productos estampados en ${productData.category ? productData.category.toLowerCase() : 'esta categoría'}, perfectos para universidades, organizaciones públicas y empresas. Nuestra colección cuenta con una amplia gama de colores, materiales y diseños, todos pensados para satisfacer tus necesidades y gustos únicos. \nNo esperes más y aprovecha nuestras ofertas exclusivas en productos personalizados estampados de alta calidad. ¡Contáctanos hoy para obtener una cotización personalizada! Nos encantaría trabajar contigo y ofrecerte precios competitivos. ¡No dudes en llamarnos o enviarnos un correo electrónico para comenzar!`;


  }
}


const products = productsData ? productsData.map(productData => new Product(productData)) : [];

const products_algodon = products.filter((item) => item.material === 'crea' || item.material === 'lona') || [];

const products_tnt = products.filter((item) => item.material === 'tnt') || [];


export { products, products_algodon, products_tnt };