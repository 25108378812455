import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert } from "@mui/material";
import { TextField, Button, FormLabel, FormGroup } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

const FORM_ENDPOINT = "https://public.herotofu.com/v1/b0c9d450-c5ab-11ed-b4ac-77574673b9e5";

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("El nombre es requerido"),
    email: Yup.string()
        .email("El correo electrónico no es válido")
        .required("El correo electrónico es requerido"),
    message: Yup.string()
        .required("El mensaje es requerido")
        .min(10, 'Mínimo 10 caracteres')
        .max(1000, 'Máximo 1000 caracteres'),
});

const ContactForm = () => {

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formError, setFormError] = useState(null);
    const { state } = useLocation();

    const initialValues = {
        name: "",
        email: "",
        message: state?.message || "",
        _gotcha: ""
    };

    useEffect(() => {
        if (state?.message) {
            setFormSubmitted(false);
            setFormError(null);
        }
    }, [state]);

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {

        try {

            const response = await fetch(FORM_ENDPOINT, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(values),
            });

            if (!response.ok) {
                console.log(response.statusText);
                throw new Error("Network response was not ok");
            }

            // handle successful form submission here
            console.log("Form submitted successfully");
            setFormSubmitted(true);
            setSubmitting(false);

        } catch (error) {
            // handle form submission error here
            console.error("Error when sending message:", error);
            setFormSubmitted(false);
            setSubmitting(false);

            setFormError('Error al enviar tu mensaje. Intenta nuevamente o escríbenos a biobolsasany@gmail.com.');

            setErrors({
                form: "Error al enviar tu mensaje",
            });
        }
    };

    return (
        <>
            {formSubmitted ? (
                <Alert severity="success">¡Enviado correctamente!</Alert>
            ) : null}

            {formError && <Alert severity="error">{formError}</Alert>}

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched }) => (
                    <Form className="contact-form py-4" method="POST" acceptCharset="UTF-8" style={{ clear: "both" }}>

                        <FormGroup controlId="name" className="my-2">
                            <FormLabel>Nombre</FormLabel>
                            <Field
                                as={TextField}
                                name="name"
                                placeholder="Nombre"
                                variant="outlined"
                                fullWidth
                                error={errors.name && touched.name}
                                helperText={<ErrorMessage name="name" />}
                            />
                        </FormGroup>
                        <FormGroup controlId="email" className="my-2">
                            <FormLabel>Correo electrónico</FormLabel>
                            <Field
                                as={TextField}
                                name="email"
                                placeholder="Correo electrónico"
                                type="email"
                                variant="outlined"
                                fullWidth
                                error={errors.email && touched.email}
                                helperText={<ErrorMessage name="email" />}
                            />
                        </FormGroup>
                        <FormGroup controlId="message" className="my-2">
                            <FormLabel>Mensaje</FormLabel>
                            <Field
                                as={TextField}
                                name="message"
                                placeholder="Cuéntanos qué producto necesitas"
                                rows="6"
                                multiline
                                variant="outlined"
                                fullWidth
                                error={errors.message && touched.message}
                                helperText={<ErrorMessage name="message" />}
                            />
                        </FormGroup>

                        {isSubmitting ?
                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition="end"
                                endIcon={<SendIcon />}
                                variant="contained"
                            >
                                Enviar
                            </LoadingButton>
                            :
                            <Button type="submit" variant="contained" color="primary" className="float-none" disabled={isSubmitting}>
                                Enviar
                            </Button>

                        }

                        <div style={{ textIndent: '-99999px', whiteSpace: 'nowrap', overflow: 'hidden', position: 'absolute' }} aria-hidden="true">
                            <Field type="text" name="_gotcha" tabIndex="-1" autoComplete="off" />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ContactForm;