import { products } from '../components/ProductsData';
import { Helmet } from 'react-helmet';
import { Container, Typography, Box } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MuiLink from '@mui/material/Link';
import { Link } from 'react-router-dom';

import ProductDetail from '../components/ProductDetail';

import FAQSection from '../components/FaqSection';
import CardsSection from '../components/CardsSection';
import { useParams } from 'react-router-dom';


const ProductDetailPage = () => {
  const { productId } = useParams();
  const selectedProduct = products.find(p => p.title.toLowerCase().replace(/\s+/g, '-') === productId);


  const breadcrumbs = selectedProduct ? [
    <MuiLink
      key="1"
      component={Link}
      to={selectedProduct.material.toLowerCase().includes('tnt') ? '/productos' : '/algodon'}
      underline="hover"
      color="inherit"
    >    Bolsas de {selectedProduct.material.toLowerCase().includes('tnt') ? 'TNT' : 'Algodón'}
    </MuiLink>,
    <Typography key="2" color="text.primary">    {selectedProduct.title}  </Typography>,
  ] : [];


  return (
    <Container>
      <Helmet>
        <title>{selectedProduct ? selectedProduct.title : "Bolsas Reutilizables de TNT"} | Biobolsas</title>
        <meta name="description" content="Descubre nuestro amplio catálogo de bolsas reutilizables de tela, TNT, algodón y yute. Personaliza tus bolsas con logotipos y diseños únicos. ¡Di chao al plástico con Biobolsas!" />
        <meta name="keywords" content="bolsas de tnt, bolsas reutilizables, bolsas de tela, bolsas de algodón, bolsas de yute, bolsas personalizadas, bolsas con logo, catálogo de productos" />
      </Helmet>

      <Box mt={4}>
        {selectedProduct && (
          <>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              mb={4}
            >
              {breadcrumbs}
            </Breadcrumbs>
            <ProductDetail product={selectedProduct} />
          </>
        )}
        {!selectedProduct && (
          <Typography variant="h4" align="center" my={5}>
            No se encontró ningún producto con el nombre "{productId}"
          </Typography>
        )}
      </Box>
      {selectedProduct &&
        (<><FAQSection mt={4} sx={{ marginTop: '2rem' }} />
          <CardsSection mt={3} /></>)
      }
    </Container>
  );
};

export default ProductDetailPage;
