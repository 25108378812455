import { Box, Typography } from '@mui/material';

const content = [
  {
    title: 'Bolsas reutilizables personalizadas para tu negocio',
    text: 'Si estás buscando una forma sostenible y atractiva de promocionar tu negocio, nuestras bolsas reutilizables personalizadas son la opción ideal. En Biobolsas.cl, te ofrecemos una amplia variedad de bolsas de TNT y materiales nobles como el algodón que es mucho más resistente y duradero, entre ellos contamos o trabajamos en lona, crea y algodón que puedes personalizar con tu logo o diseño. Además, te ofrecemos precios competitivos y descuentos en venta al por mayor. ¡Contáctanos para más información!'
  },
  {
    title: 'Bolsas reutilizables de alta calidad',
    text: 'En Biobolsas.cl, creemos que las bolsas reutilizables deberían ser accesibles para todos. Es por eso que te ofrecemos una amplia variedad de bolsas de TNT, lona, crea  y osnaburgo y crea de alta calidad a precios competitivos. Además, nuestras bolsas son resistentes y duraderas, lo que las convierte en una excelente inversión a largo plazo. ¡Compra tus bolsas reutilizables ahora y comienza a cuidar el planeta!'
  },
  {
    title: 'Las ventajas de usar bolsas reutilizables en tu negocio',
    text: 'Si tienes un negocio, las bolsas reutilizables pueden ser una excelente opción para reducir costos y cuidar el medio ambiente. En Biobolsas.cl te ofrecemos una amplia variedad de bolsas de TNT, lona, crea  y osnaburgo y crea que se adaptan a tus necesidades y presupuesto. Además, nuestras bolsas personalizadas son ideales para promocionar tu marca de forma sostenible y atractiva. ¡Contáctanos para más información!'
  },
  {
    title: '¿Qué hace que nuestras bolsas ecológicas sean diferentes?',
    text: 'En Biobolsas, nos preocupamos por ofrecerte las mejores bolsas reutilizables de alta calidad, elaboradas con materiales resistentes y amigables con el medio ambiente. Nuestras bolsas de TNT y algodón son lavables y resistentes, por lo que podrás utilizarlas por mucho tiempo sin tener que preocuparte por su durabilidad. Además, nuestras bolsas personalizadas son ideales para promocionar tu marca o producto de forma sostenible y atractiva. ¡Contáctanos para más información!'
  },
  {
    title: 'Tipos de bolsas reutilizables',
    text: 'Existen varios tipos de telas para fabricar bolsas reutilizables, entre ellas destacan:',
    list: [
      {
        title: 'TNT',
        text: 'El TNT (Tela no tejida) es una tela resistente, ligera y muy utilizada en la fabricación de bolsas reutilizables debido a su bajo costo y facilidad de estampado. Es un material reciclable y resistente al agua.',
      },
      {
        title: 'Osnaburgo',
        text: 'La tela de osnaburgo es un tejido grueso y resistente elaborado en su totalidad con 100% algodón y posee una trama rústica que le brinda una textura única.',
      },
      {
        title: 'Crea',
        text: 'La tela crea es 100% algodón, resistente y facil de lavar. Su suavidad y textura lisa permiten un estampado serigráfico de alta calidad.',
      },
      {
        title: 'Lona',
        text: 'La lona es una tela 100% algodón gruesa y resistente, lo que la hace duradera y capaz de soportar más peso.',
      },
    ]
  }

];


const ContentSection = () => {
  return (
    <Box mt={5}>
      {content.map((item, index) => (
        <Box my={3} key={index}>
          <Typography variant="h5" gutterBottom>{item.title}</Typography>
          <Typography variant="body1" align='justify' paragraph>{item.text}</Typography>
          {item.list && (
            <Box component="ul" pl={3}>
              {item.list.map((listItem, index) => (
                <Box component="li" key={index} my={1}>
                  <Typography variant="body1" align='justify'>{listItem.title}: {listItem.text}</Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};


export default ContentSection;