import { useEffect } from 'react';
import Carousel from '../components/Carousel';
import ProductList from '../components/ProductList';
import { products } from '../components/ProductsData';
import { Helmet } from 'react-helmet';
import { Typography, Box, Container } from '@mui/material';
import styled from '@emotion/styled';
import logo from '../assets/images/biobolsas.png';

const pageData = {
  title: "Bolsas Reutilizables de TNT, Tela, Algodón y Crea Personalizadas | Biobolsas",
  description: "Descubre nuestras bolsas reutilizables de TNT, tela, algodón, crea y yute personalizadas con tu logo. ¡Di chao al plástico con Biobolsas!",
  imageUrl: logo,
};

const StyledBox = styled(Box)`
  text-align: center;
  text-transform: uppercase;
`;

const HomePage = () => {
  useEffect(() => {
    document.title = pageData.title;
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content="bolsas de tnt, bolsas reutilizables, bolsas de tela, bolsas de algodón, bolsas de yute, bolsas personalizadas, bolsas con logo, catálogo de productos" />

        <meta property="og:title" content={pageData.title} />
        <meta property="og:description" content={pageData.description} />
        <meta property="og:url" content="https://biobolsas.cl" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={pageData.imageUrl} />
      </Helmet>

      <Carousel />
      <Container>
        <Box mt={4}>
          <StyledBox>
            <Typography variant="subtitle1">Descubre</Typography>
            <Typography variant="h2">Nuestros Productos</Typography>
          </StyledBox>
        </Box>
        <ProductList products={products} showAll={false} />
      </Container>
    </div>
  );
};

export default HomePage;
