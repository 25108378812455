import { css } from '@emotion/react';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { Info, LocalShipping, Payment } from '@mui/icons-material';

const cards = [
  {
    title: 'Envíos',
    icon: <LocalShipping fontSize="large" />,
    content: 'Hacemos envíos a todo Chile mediante Transportes Chevalier, Starken, Chilexpress y Pullman Cargo u otro medio a convenir, también contamos con Retiro en la oficina.',
  },
  {
    title: 'Atención Personalizada',
    icon: <Info fontSize="large" />,
    content: 'Fabricamos bolsas de tela y personalizamos nuestros productos de acuerdo a la necesidad de cada cliente. Si necesitas un tamaño o diseño específico, no dudes en contactarnos.',
  },
  {
    title: 'Pago Seguro',
    icon: <Payment fontSize="large"  />,
    content: 'Compra de forma rápida y segura. Podrás realizar tus pagos mediante transferencia electrónica o mediante tarjeta si retiras tu pedido en nuestra oficina. Ofrecemos diferentes opciones de pago para que puedas elegir la que más te conviene.',
  },
];



const CardsSection = () => {

  const cardStyles = css`
    margin: 8px;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: justify;
    height: '100%',

    @media (max-width: 599px) {
      flex-direction: row;
      align-items: flex-start;
    }
  `;


  return (
    <Grid container spacing={2} mt={5}>
      {cards.map((card) => (
        <Grid item xs={12} sm={4} key={card.title}>
          <Card sx={cardStyles}>
            <CardHeader
              title={
                <Typography variant="h5" sx={{ fontWeight: 'bold'}}>
                  {card.title}
                </Typography>
              }
              avatar={card.icon}
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" align='justify'>
                {card.content}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardsSection;
