import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3ba12b',
    },
    secondary: {
      main: '#a18276',
    },
    tertiary: {
      main: '#30bced',
    },
    quaternary: {
      main: '#333232',
    },
    fabric: {
      main: '#f3eff5'
    },
    wood: {
      main: '#a18276'
    }
    // background: {
    //   default: '#fffaff',
    // },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
          textDecoration: 'none',
          '&:hover.MuiButton-text': {
            color: '#333232',
            backgroundColor: '#3ba12b33',
          },
        },
      },
    },    
    MuiPaper: {
      styleOverrides: {
        root: {

        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '0.75rem',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
        },
      },
  
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          '&:hover': {
            color: '#30BCED',
            textDecoration: 'underline',
          },
        },
      },
    }
  },

  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    button: {
      textAlign: 'center',
      fontWeight: 'medium',
    },
    h1: {
      fontWeight: 'bold',
    },
    h2: {
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 'bold',
    },
    h4: {

    },
    h5: {

    },
    h6: {

    },
  },
});

export default theme;
