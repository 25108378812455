import { useEffect } from 'react';

import Navbar from './components/Navbar'; // O './components/Navbar' si lo colocaste en una subcarpeta.
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import HomePage from './pages/HomePage';
import ProductsPage from './pages/ProductsPage';
import ContactPage from './pages/ContactPage';
import AlgodonPage from './pages/AlgodonPage';
import Footer from './components/Footer';
import AboutPage from './pages/AboutPage';
import ProductDetailPage from './pages/ProductDetailPage';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import theme from './config/theme';
import createEmotionCache from './config/createEmotionCache';

import ReactGA from 'react-ga4';

const GA_MEASUREMENT_ID = "G-0Q78S58BEM";

ReactGA.initialize([
  {
    trackingId: GA_MEASUREMENT_ID,
    testMode: process.env.ENV === 'prod' ? false : true
  }
]);

const clientSideEmotionCache = createEmotionCache();

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);

  return null;
};

function App(props) {
  const { emotionCache = clientSideEmotionCache } = props;
  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Router>
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/productos" element={<ProductsPage />} />
            <Route path="/algodon" element={<AlgodonPage />} />
            <Route path="/contacto" element={<ContactPage />} />
            <Route path="/acerca-de" element={<AboutPage />} />
            <Route path="/productos/detalle/:productId" element={<ProductDetailPage />} />
          </Routes>
          <Footer />
        </Router>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;

