import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ProductImage from './ProductImage';
import AvailableColors from './AvailableColors';
import QuantitySelector from './QuantitySelector';
import ProductInfo from './ProductInfo';
import QuotationButton from './QuotationButton';

const MobileProductDetail = ({ product }) => {

    const [quantity, setQuantity] = useState(100);

    const handleQuantityChange = (newQuantity) => {
        setQuantity(newQuantity);
    };

    const [selectedColor, setSelectedColor] = useState(product.colors && product.colors[0]);

    const handleColorChange = (color) => {
        setSelectedColor(color);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <Typography variant="h4" component="h1" sx={{ mt: 2 }}>
                {product.title}
            </Typography>

            {product.material && product.material === 'tnt' && (
                <Typography variant="subtitle1" mb={2} sx={{ color: 'rgba(0, 0, 0, 0.4)' }}>
                    Incluye estampado un color, una cara
                </Typography>
            )}

            <ProductImage size="sm" imageFileName={product.imageFileName} altText={product.altText} />

            {product.price && (
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <Typography variant="h5" component="span">
                        {`$${product.price.toLocaleString('es-CL', { useGrouping: true }).replace(/,/g, '.')}`}
                    </Typography>
                    <Typography variant="caption" component="span" sx={{ ml: 2, color: 'text.secondary', textTransform: 'uppercase' }}>
                        Precio + IVA
                    </Typography>
                </Box>
            )}

            {product.colors && (
                <Box sx={{ mt: 2 }}>
                    <AvailableColors colors={product.colors} selectedColor={selectedColor} onColorChange={handleColorChange} />
                </Box>
            )}

            <QuantitySelector quantity={quantity} onQuantityChange={handleQuantityChange} />


            <QuotationButton product={product} quantity={quantity} />


            <ProductInfo product={product} />
        </Box>
    );
};

export default MobileProductDetail;
