import { useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
    {
        question: '¿Cuál es el precio de las bolsas reutilizables?',
        answer: 'El precio de las bolsas reutilizables depende del tipo de material, tamaño y personalización que elijas. En nuestro sitio web encontrarás una amplia variedad de opciones con distintos precios.',
    },
    {
        question: '¿Cuáles son los beneficios de usar bolsas reutilizables?',
        answer: 'Usar bolsas reutilizables tiene múltiples beneficios. Ayuda a reducir el impacto ambiental, ya que se reducen los residuos de plástico. Además, son más resistentes y duraderas que las bolsas de plástico desechables, por lo que te durarán mucho más tiempo y ahorrarás dinero a largo plazo. También son más cómodas de llevar y suelen tener más capacidad que las bolsas de plástico.',
    },
    {
        question: '¿Cómo puedo lavar las bolsas reutilizables?',
        answer: 'La mayoría de las bolsas reutilizables son lavables. Para lavarlas, simplemente colócalas en la lavadora con agua fría y detergente suave. Evita usar suavizantes y secarlas en la secadora, ya que pueden dañar el material. Deja que se sequen al aire libre y estarán listas para usar de nuevo.',
    },
    {
        question: '¿Cuáles son las diferencias entre las bolsas de tela y las bolsas de plástico?',
        answer: 'Las principales diferencias entre las bolsas de tela y las bolsas de plástico son su durabilidad y su impacto ambiental. Las bolsas de tela son más resistentes y duraderas, lo que significa que pueden ser utilizadas muchas veces antes de necesitar ser reemplazadas. Por otro lado, las bolsas de plástico son de un solo uso y tienen un gran impacto ambiental debido a que tardan años en descomponerse.',
    },
    {
        question: '¿Son las bolsas reutilizables más resistentes que las bolsas de plástico?',
        answer: 'Sí, las bolsas reutilizables son generalmente más resistentes que las bolsas de plástico de un solo uso. Están diseñadas para ser reutilizadas muchas veces y están hechas de materiales más resistentes como tela, algodón o yute. Las bolsas de plástico se desgastan fácilmente y pueden romperse en cualquier momento.',
    },
];

const FAQSection = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (index) => (event, isExpanded) => {
        setExpanded(isExpanded ? index : false);
    };

    return (
        <Box mt={5}>
            <Typography variant="h4" gutterBottom>
                Preguntas Frecuentes
            </Typography>
            {faqs.map((faq, index) => (
                <Accordion
                    key={index}
                    expanded={expanded === index}
                    onChange={handleChange(index)}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{faq.answer}</Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default FAQSection;

