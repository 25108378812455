import ProductList from '../components/ProductList';
import { products_algodon } from '../components/ProductsData';
import { Helmet } from "react-helmet";
import { Container, Box, Typography, Grid } from '@mui/material';

const CottonBagsPage = () => {
  return (
    <Container>
      <Helmet>
        <title>Bolsas de Algodón Reutilizables | Biobolsas</title>
        <meta name="description" content="Descubre nuestra amplia variedad de bolsas de algodón reutilizables. Personaliza tus bolsas con logotipos y diseños únicos. ¡Di chao al plástico con Biobolsas!" />
        <meta name="keywords" content="bolsas de tnt, bolsas reutilizables, bolsas de tela, bolsas de algodón, bolsas de yute, bolsas personalizadas, bolsas con logo, catálogo de productos" />
      </Helmet>
      <Box mt={4}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} justifyContent="center" textAlign="center">
            <Typography variant="overline" component="small" align="center">
              Descubre Nuestras
            </Typography>
            <Typography variant="h2" align="center">
              Bolsas de Algodón
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box mt={4}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="body1" align="justify" sx={{ padding: '0 3rem', display: { xs: 'none', md: 'block' } }}>
              Aqui encontrarás una amplia variedad de bolsas de algodón reutilizables, una opción sostenible y resistente a las bolsas de plástico de un solo uso. Estas bolsas están diseñadas para ofrecer una alternativa ecológica y personalizable para tu empresa o marca. Nuestras bolsas de algodón están fabricadas con materiales de alta calidad y bajo los más altos estándares de producción. Además, son personalizables con el logo o diseño que desees, lo que las convierte en una herramienta de marketing única y efectiva. Disponibles en una gran variedad de tamaños, podrás elegir la opción que mejor se adapte a tus necesidades.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <ProductList products={products_algodon} />
    </Container>
  );
};

export default CottonBagsPage;
