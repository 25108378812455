import useMediaQuery from '@mui/material/useMediaQuery';

import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';

import MobileProductDetail from './MobileProductDetail';
import DesktopProductDetail from './DesktopProductDetail';

const ProductDetail = ({ product }) => {
    const structuredData = {
        "@context": "http://schema.org/",
        "@type": "Product",
        "name": product.title,
        "description": product.description,
        "image": product.imageFileName,
        "brand": {
            "@type": "Brand",
            "name": "Biobolsas"
        },
        "offers": {
            "@type": "Offer",
            "priceCurrency": "CLP",
            "price": product.price,
            "availability": "https://schema.org/InStock"
        }
    };

    return (
        <>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
            </Helmet>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {useMediaQuery((theme) => theme.breakpoints.down('sm')) ? (
                    <MobileProductDetail product={product} />
                ) : (
                    <DesktopProductDetail product={product} />
                )}
            </Box>
        </>
    );
};

export default ProductDetail;
