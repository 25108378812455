import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ProductImage from './ProductImage';
import AvailableColors from './AvailableColors';
import QuantitySelector from './QuantitySelector';
import ProductInfo from './ProductInfo';
import QuotationButton from './QuotationButton';

const DesktopProductDetail = ({ product }) => {

    const [quantity, setQuantity] = useState(100);

    const handleQuantityChange = (newQuantity) => {
        setQuantity(newQuantity);
    };

    const [selectedColor, setSelectedColor] = useState(product.colors && product.colors[0]);

    const handleColorChange = (color) => {
        setSelectedColor(color);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ width: '85%', display: 'flex', flexWrap: 'wrap' }}>
                <Box sx={{ flexGrow: 1, mr: '2rem' }}>
                    <ProductImage size='sm' imageFileName={product.imageFileName} altText={product.altText} />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant='h4' component='h1' sx={{ mt: 0 }}>
                        {product.title}
                    </Typography>
                    {product.material && product.material === 'tnt' &&
                        <Typography variant='subtitle1' sx={{ color: 'rgba(0, 0, 0, 0.4)' }}>
                            Incluye estampado un color, una cara
                        </Typography>
                    }

                    {product.price &&
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>

                            <Typography variant='h5' component='span'>
                                {`$${product.price.toLocaleString('es-CL', { useGrouping: true }).replace(/,/g, '.')}`}
                            </Typography>

                            <Typography variant='caption' component='span' sx={{ ml: 2, color: 'text.secondary', textTransform: 'uppercase' }}>
                                Precio + IVA
                            </Typography>

                        </Box>
                    }
                    {product.colors && (
                        <Box sx={{ mt: 2 }}>
                            <AvailableColors colors={product.colors} selectedColor={selectedColor} onColorChange={handleColorChange} />
                        </Box>
                    )}

                    <QuantitySelector quantity={quantity} onQuantityChange={handleQuantityChange} />


                    <QuotationButton product={product} quantity={quantity} />

                </Box>

                <ProductInfo product={product} />

            </Box>
        </Box>
    );

};

export default DesktopProductDetail;