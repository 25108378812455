import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//import { AppBar, Container, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem } from '@mui/material';

import { Container } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { css } from '@emotion/react';
import MenuIcon from '@mui/icons-material/Menu';

import logo from '../assets/images/biobolsas.png';
import logoWebp from '../assets/images/biobolsas.webp';



const menuButtonStyle = css`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;


const drawerWidth = 240;





const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (isOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(isOpen);
  };


  const menuItems = [
    { title: 'Inicio', path: '/' },
    { title: 'Bolsas TNT', path: '/productos' },
    { title: 'Bolsas Algodón', path: '/algodon' },
    { title: 'Contáctanos', path: '/contacto' },
  ];

  const drawer = (
    <Box onClick={toggleDrawer} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Biobolsas
      </Typography>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} as={Link} to={item.path}>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  //const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <AppBar position="sticky" sx={{backgroundColor: '#ffffff',  }}>
        <Helmet>
          <link rel="preload" as="image" href={logoWebp} type="image/webp" />
          <link rel="preload" as="image" href={logo} />
        </Helmet>
        <Container maxWidth="lg">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Link to="/">
                <picture>
                  <source srcSet={logoWebp} type="image/webp" />
                  <img src={logo} alt="Logo biobolsas" width={180} />
                </picture>
              </Link>
            </Typography>
            {menuItems.map((item) => (
              <Button key={item.title} component={Link} to={item.path} sx={{ display: { xs: 'none', sm: 'block' } }} >
                {item.title}
              </Button>
            ))}
            <IconButton edge="end" aria-label="menu" sx={menuButtonStyle} onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        anchor="right"
        variant="temporary"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>

    </>

  );
};

export default Navbar;
