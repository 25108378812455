import { Link, useNavigate } from 'react-router-dom';
import { Box, Chip, Card, CardMedia, CardActionArea, CardActions, CardContent, Typography, Button } from '@mui/material';
import ProductImage from './ProductImage';
import AvailableColors from './AvailableColors';

const ProductCard = ({ product }) => {

    const navigate = useNavigate();
    
    const handleCardClick = () => {
        navigate(product.url);
      };

    return (
        <Card sx={{
            boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            transition: 'all 0.3s ease 0s',
            '&:hover': {
                transform: 'translateY(-0.25rem)',
                boxShadow: '0px 7.5px 15px -3.75px rgba(0,0,0,0.2), 0px 3px 15px 0px rgba(0,0,0,0.14), 0px 3.75px 3.75px -2.25px rgba(0,0,0,0.12)'
            }
        }}>
            <CardActionArea onClick={handleCardClick}>
                <ProductImage
                    as={CardMedia}
                    imageFileName={product.imageFileName}
                    altText={product.altText}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {product.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {product.shortDescription}
                    </Typography>
                    {product.tags && (
                        <Box mt={2}>
                            {product.tags.map((tag, index) => (
                                <Chip key={index} label={tag} variant='outlined' sx={{ mr: 1, mb: 1, borderRadius: '12px', }} />
                            ))}
                        </Box>
                    )}
                    {product.colors && (
                        <Box mt={1}>
                            <AvailableColors colors={product.colors} />
                        </Box>
                    )}
                </CardContent>

                <CardActions sx={{ display: 'flex', justifyContent: product.price ? 'space-between' : 'flex-end', px: 2 }}>
                    {product.price && (
                        <>
                            <div>
                                <Typography variant="caption" component="p" sx={{ color: 'text.secondary', textTransform: 'uppercase' }}>
                                    Precio + IVA
                                </Typography>
                                <Typography variant="h6" component="p">
                                    {`$${product.price.toLocaleString('es-CL', { useGrouping: true }).replace(/,/g, '.')}`}

                                </Typography>
                            </div>
                        </>
                    )}
                    <Button color="primary" as={Link} to={product.url}>Ver detalle</Button>
                </CardActions>
            </CardActionArea>
        </Card>
    );
};

export default ProductCard;
