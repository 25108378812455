import { Box, Button, Typography, Input } from '@mui/material';
import { useState } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const QuantitySelector = ({ minimumQuantity = 100, maximumQuantity = 10000, onQuantityChange }) => {
    const [quantity, setQuantity] = useState(minimumQuantity);

    const handleQuantityChange = (event) => {
        let newQuantity = event.target.value;

        // Remove non-digit characters from input
        newQuantity = newQuantity.replace(/\D/g, '');

        if (newQuantity < minimumQuantity) {
            newQuantity = minimumQuantity;
        } else if (newQuantity > maximumQuantity) {
            newQuantity = maximumQuantity;
        }

        setQuantity(newQuantity);
        onQuantityChange(newQuantity);
    };

    const handleDecrement = () => {
        const newQuantity = quantity - 1;
        if (newQuantity >= minimumQuantity) {
            setQuantity(newQuantity);
            onQuantityChange(newQuantity);
        }
    };

    const handleIncrement = () => {
        const newQuantity = quantity + 1;
        if (newQuantity <= maximumQuantity) {
            setQuantity(newQuantity);
            onQuantityChange(newQuantity);
        }
    };

    const buttonStyles = {
        borderRadius: '5px',
        color: '#333333',
        height: '2rem',
        minWidth: '2rem',
        backgroundColor: '#f1f1f1',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
            boxShadow: 'none',
        },
        '&:active': {
            backgroundColor: '#d1d1d1',
            boxShadow: 'none',
        },
        '&:disabled': {
            backgroundColor: '#f1f1f1',
            color: '#9e9e9e',
            boxShadow: 'none',
        },
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    onClick={handleDecrement}
                    disabled={quantity <= minimumQuantity}
                    sx={buttonStyles}
                >
                    <RemoveIcon />
                </Button>
                <Box sx={{ mx: 1 }}>
                    <Input
                        type='number'
                        value={quantity}
                        onChange={handleQuantityChange}
                        inputProps={{
                            min: minimumQuantity,
                            max: maximumQuantity,
                            style: {
                                width: '6rem',
                                '-webkit-appearance': 'none',
                                '-moz-appearance': 'textfield',
                                textAlign: 'center',
                            },
                        }}
                        sx={{
                            WebkitAppearance: 'none',
                            textAlign: 'center',
                        }}
                    />
                </Box>
                <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    onClick={handleIncrement}
                    disabled={quantity >= maximumQuantity}
                    sx={buttonStyles}
                >
                    <AddIcon />
                </Button>
            </Box>
            <Typography variant='caption' component='span' sx={{ mt: { xs: 2, md: 0 }, ml: { xs: 0, md: 2 }, color: 'text.secondary', textTransform: 'uppercase' }}>
                Mínimo {minimumQuantity} unidades
            </Typography>
        </Box>
    );
};

export default QuantitySelector;
