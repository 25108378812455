import { Container, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import placeholderImage from '../assets/images/2.jpg';

const AboutImage = styled('img')`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const AboutPage = () => {
  return (
    <Container>
      <Typography variant="h2" component="h2" gutterBottom>
        Acerca de nosotros
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <AboutImage src={placeholderImage} alt="Nosotros" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography paragraph>
            Somos una empresa que nace en la ciudad de Los Ángeles en el año 2009, desde entonces hemos sido líderes en la fabricación de bolsas ecológicas de alta calidad, incluyendo bolsas de TNT, bolsas reutilizables, bolsas de tela, bolsas de algodón, bolsas de yute y bolsas personalizadas con logo. Nuestra empresa se enfoca en ofrecer productos que reduzcan la huella ambiental de nuestros clientes.
          </Typography>
          <Typography paragraph>
            Contamos con un catálogo de productos que se adaptan a las necesidades de cada cliente, y todos ellos son fabricados bajo los más altos estándares de calidad. Además, nos aseguramos de cumplir con los plazos de entrega acordados.
          </Typography>
          <Typography paragraph>
            Si deseas contribuir a un mundo más sostenible y limpio, no dudes en adquirir nuestras bolsas ecológicas. Visita nuestro catálogo en línea para conocer más acerca de nuestros productos y personaliza tus bolsas con el logo de tu empresa o marca.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutPage;
