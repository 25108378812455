import { Box, Container, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import contactImage from '../assets/images/2.jpg';
import contactImageWebp from '../assets/images/2.webp';
import { Helmet } from "react-helmet";
import ContactForm from '../components/ContactForm';

const ContactImage = styled('img')`
  width: 100%;
  height: auto;
`;

const ContactPage = () => {
  return (
    <div>
      <Helmet>
        <title>Contacta con nosotros | Biobolsas</title>
        <meta name="description" content="Contactanos y descubre nuestra amplia variedad de bolsas de algodón reutilizables. Personaliza tus bolsas con logotipos y diseños únicos. ¡Di chao al plástico con Biobolsas!" />
        <meta name="keywords" content="bolsas de tnt, bolsas reutilizables, bolsas de tela, bolsas de algodón, bolsas de yute, bolsas personalizadas, bolsas con logo, catálogo de productos" />
      </Helmet>
      <Container>
        <Box mt={4} px={2}>
          <Typography variant="overline" component="small">Cotiza tus bolsas reutilizables</Typography>
          <Typography variant="h3" component="h3" gutterBottom>
            Contacta con nosotros
          </Typography>
          <Typography paragraph sx={{ display: { xs: 'none', 'md': 'block' } }}>
            Si estás buscando comprar bolsas reutilizables de tela, algodón o yute, personalizadas con tu logo, estás en el lugar indicado. En nuestra tienda online ofrecemos una amplia variedad de modelos y materiales para que puedas elegir la que mejor se adapte a tus necesidades.
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <ContactForm />
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <picture>
              <source srcSet={contactImageWebp} type="image/webp" />
              <ContactImage src={contactImage} alt="Contact" />
            </picture>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ContactPage;
