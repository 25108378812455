import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';

const QuotationButton = ({ product, quantity }) => {
    const navigate = useNavigate();

    const handleQuote = () => {
        if (!product || !product.title) {
            console.error('Product is invalid');
            return;
        }

        if (!quantity || quantity <= 0 || !Number.isInteger(quantity)) {
            console.error('Quantity is invalid');
            return;
        }

        const formData = { message: `Estimados\nSolicito cotizar ${quantity} ${product.title}.\nSaludos` };
        navigate('/contacto', { state: formData });
    };

    return (
        <Box sx={{ mt: 4, width: '60%' }}>
            <Button variant="contained" size="large" fullWidth color="primary" onClick={handleQuote}>
                Cotizar
            </Button>
        </Box>
    );
};

export default QuotationButton;
